import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

import { useTranslation } from 'gatsby-plugin-react-i18next'

const PrivacyPolicyPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('seo_title')} />
      <section className='py-8 page-content'>
        <div className='container mx-auto px-4'>
          <div className='w-full lg:w-2/3'>
            <h1 className='text-4xl lg:text-6xl font-display font-black leading-tight mb-8'>
              {t('policy:page_title')}
            </h1>
            <h2 className='text-3xl lg:text-4xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_intro.head')}
            </h2>
            <p>{t('policy:pp_intro.paragraph_01')}</p>
            <p>{t('policy:pp_intro.paragraph_02')}</p>
            <p>{t('policy:pp_intro.paragraph_03')}</p>
            <p>{t('policy:pp_intro.paragraph_04')}</p>
            <p>{t('policy:pp_intro.paragraph_05')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_01.head')}
            </h3>
            <p>{t('policy:pp_01.paragraph_01')}</p>
            <p>{t('policy:pp_01.paragraph_02')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_02.head')}
            </h3>
            <p>{t('policy:pp_02.paragraph_01')}</p>
            <ul className='list'>
              <li>{t('policy:pp_02.list.01')}</li>
              <li>{t('policy:pp_02.list.02')}</li>
              <li>{t('policy:pp_02.list.03')}</li>
            </ul>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_03.head')}
            </h3>
            <p>{t('policy:pp_03.paragraph_01')}</p>
            <ul className='list'>
              <li>{t('policy:pp_03.list.01')}</li>
              <li>{t('policy:pp_03.list.02')}</li>
              <li>{t('policy:pp_03.list.03')}</li>
            </ul>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_04.head')}
            </h3>
            <p>{t('policy:pp_04.paragraph_01')}</p>
            <ul className='list'>
              <li>{t('policy:pp_04.list.01')}</li>
              <li>{t('policy:pp_04.list.02')}</li>
              <li>{t('policy:pp_04.list.03')}</li>
              <li>{t('policy:pp_04.list.04')}</li>
              <li>{t('policy:pp_04.list.05')}</li>
              <li>{t('policy:pp_04.list.06')}</li>
              <li>{t('policy:pp_04.list.07')}</li>
            </ul>
            <p>{t('policy:pp_04.paragraph_02')}</p>
            <ul className='list'>
              <li>{t('policy:pp_04.list_02.01')}</li>
              <li>{t('policy:pp_04.list_02.02')}</li>
              <li>{t('policy:pp_04.list_02.03')}</li>
              <li>{t('policy:pp_04.list_02.04')}</li>
              <li>{t('policy:pp_04.list_02.05')}</li>
              <li>{t('policy:pp_04.list_02.06')}</li>
            </ul>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_05.head')}
            </h3>
            <p>{t('policy:pp_05.paragraph_01')}</p>
            <p>{t('policy:pp_05.paragraph_01')}</p>
            <p>{t('policy:pp_05.paragraph_01')}</p>
            <ul className='list'>
              <li>{t('policy:pp_05.list.01')}</li>
              <li>{t('policy:pp_05.list.02')}</li>
              <li>{t('policy:pp_05.list.03')}</li>
              <li>{t('policy:pp_05.list.04')}</li>
            </ul>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_06.head')}
            </h3>
            <p>{t('policy:pp_06.paragraph_01')}</p>
            <p>{t('policy:pp_06.paragraph_01')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:pp_07.head')}
            </h3>
            <p>{t('policy:pp_07.paragraph_01')}</p>
            <ul className='list'>
              <li>{t('policy:pp_07.list.01')}</li>
              <li>{t('policy:pp_07.list.02')}</li>
              <li>{t('policy:pp_07.list.03')}</li>
              <li>{t('policy:pp_07.list.04')}</li>
              <li>{t('policy:pp_07.list.05')}</li>
              <li>{t('policy:pp_07.list.06')}</li>
            </ul>
            <p>{t('policy:pp_07.paragraph_02')}</p>
            <p>{t('policy:pp_07.paragraph_03')}</p>
            <p>{t('policy:pp_07.paragraph_04')}</p>
            <h2 className='text-3xl lg:text-4xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_intro.head')}
            </h2>
            <p>{t('policy:pp_intro.paragraph_01')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_01.head')}
            </h3>
            <p>{t('policy:cp_01.paragraph_01')}</p>
            <ul className='list'>
              <li>{t('policy:cp_01.list.01')}</li>
              <li>{t('policy:cp_01.list.02')}</li>
              <li>{t('policy:cp_01.list.03')}</li>
            </ul>
            <p>{t('policy:cp_01.paragraph_02')}</p>
            <ul className='list'>
              <li>{t('policy:cp_01.list_02.01')}</li>
              <li>{t('policy:cp_01.list_02.02')}</li>
              <li>{t('policy:cp_01.list_02.03')}</li>
              <li>{t('policy:cp_01.list_02.04')}</li>
              <li>{t('policy:cp_01.list_02.05')}</li>
            </ul>

            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_02.head')}
            </h3>
            <p>{t('policy:cp_02.paragraph_01')}</p>
            <p>{t('policy:cp_02.paragraph_02')}</p>
            <ul className='list'>
              <li>{t('policy:cp_02.list.01')}</li>
              <li>{t('policy:cp_02.list.02')}</li>
              <li>{t('policy:cp_02.list.03')}</li>
              <li>{t('policy:cp_02.list.04')}</li>
              <li>{t('policy:cp_02.list.05')}</li>
            </ul>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_03.head')}
            </h3>
            <p>{t('policy:cp_03.paragraph_01')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_04.head')}
            </h3>
            <p>{t('policy:cp_04.paragraph_01')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_05.head')}
            </h3>
            <p>{t('policy:cp_05.paragraph_01')}</p>
            <h3 className='text-2xl lg:text-3xl font-display font-black tracking-wide mt-6 mb-6'>
              {t('policy:cp_06.head')}
            </h3>
            <p>{t('policy:cp_06.paragraph_01')}</p>
            <p>{t('policy:cp_06.paragraph_02')}</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
